/*
 * @Author: liyu
 * @Date: 2022-10-13 15:42:27
 * @LastEditTime: 2022-10-20 16:44:00
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022Playback/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
// import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import Ds2022PlaybackCard from 'components/Topic2022/Ds2022PlaybackCard'
import Ds2022PlaybackMb from 'components/Topic2022/Ds2022PlaybackMb'
import * as styles from './index.module.less'

interface Ds2022PlaybackProps {
  className?: string
  dataSource: any
}

const Ds2022Playback: React.FC<Ds2022PlaybackProps> = ({ className, dataSource = [] }) => {
  return (
    <>
      {/* pc */}
      <div className={classnames(className, ' flex justify-center', styles.playbackContainer)}>
        <a href={dataSource[0]?.btn_href} target="_blank" className={classnames('w-[590px] relative')}>
          <div>
            <img
              className="w-full h-full ds2022_card_img"
              data-src={dataSource[0]?.img_url?.publicURL || dataSource[0]?.img_url}
              alt={dataSource[0]?.title}
            />
            {/* <GatsbyImage image={getImage(dataSource[0]?.img_url) as IGatsbyImageData} alt={dataSource[0]?.title} /> */}
          </div>
          <div className={classnames('pl-[26px] pb-[32px] bg-[rgba(13,26,41,0.7)]', styles.leftBottomCard)}>
            <div className={classnames('pt-[20px] text-[24px] font-medium ', styles.textColor)}>
              {dataSource[0]?.title}
            </div>
            <div className="text-[18px] text-[#fff]">{dataSource[0]?.desc}</div>
          </div>
          <div className={classnames('absolute bottom-[32px] right-[25px] py-[5px] px-[17px]', styles.btn)}>
            <span
              className={classnames(styles.textIcon, 'inline-block  translate-y-[3px] w-[24px] h-[15px] mr-[13px]')}
            ></span>
            <span className={classnames(styles.textColor, 'font-medium leading-[24px] ')}>
              {dataSource[0]?.btn_text}
            </span>
          </div>
        </a>
        <div className={classnames('flex-1 h-[447px]  ml-[10px]  overflow-hidden')}>
          <div className={classnames(' h-[100%] overflow-y-scroll pr-[20px]', styles.playbackRight)}>
            {dataSource?.map((el, index) => {
              if (index > 0) {
                return <Ds2022PlaybackCard dataSource={el} className={styles.playbackCard} key={index} />
              }
            })}
          </div>
        </div>
      </div>
      {/* 移动 */}
      <Ds2022PlaybackMb dataSource={dataSource} className={styles.playbackContainerMb} />
    </>
  )
}
export default Ds2022Playback

import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface Ds2022PlaybackCardProps {
  className?: string
  dataSource: any
}

const Ds2022PlaybackCard: React.FC<Ds2022PlaybackCardProps> = ({ className, dataSource }) => {
  return (
    <a
      href={dataSource?.btn_href}
      target="_blank"
      className={classnames(className, 'h-[178px] flex bg-[rgba(13,26,41,0.7)] ')}
    >
      <div className="w-[316px] ">
        {/* <GatsbyImage
          className="h-[100%]"
          image={getImage(dataSource?.img_url) as IGatsbyImageData}
          alt={dataSource?.title}
        /> */}
        <img
          className="w-full h-[100%] ds2022_card_img"
          data-src={dataSource?.img_url?.publicURL || dataSource?.img_url}
          alt={dataSource?.title}
        />
      </div>
      <div className={classnames('flex-1 px-[20px] text-[#fff] relative', styles.playbackCardRightContainer)}>
        <div className="mt-[25px] text-[20px] font-medium leading-[28px]">{dataSource?.title}</div>
        <div className="mt-[8px] text-[16px] leading-[22px]">{dataSource?.desc}</div>
        <div className={classnames('absolute left-[20px] bottom-[26px] py-[2px] px-[10px]', styles.rightPlaybackBtn)}>
          <span className={styles.rightIcon}></span>
          <span className={classnames('inline-block text-[12px] font-medium leading-[24px]', styles.rightTextColor)}>
            {dataSource?.btn_text}
          </span>
        </div>
      </div>
    </a>
  )
}

export default Ds2022PlaybackCard

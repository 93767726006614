/*
 * @Author: liyu
 * @Date: 2022-10-19 10:38:33
 * @LastEditTime: 2022-10-28 16:17:59
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022LiveMb/index.tsx
 */
import React, { useEffect, useRef } from 'react'
import Swiper from 'swiper'

import classnames from 'classnames'
import * as styles from './index.module.less'
interface Ds2022LiveMbProps {
  className?: string
  dataSource: any
}

const Ds2022LiveMb: React.FC<Ds2022LiveMbProps> = ({ className, dataSource }) => {
  const liveSiwper = useRef<Swiper>()
  useEffect(() => {
    liveSiwper.current = new Swiper('#live-swiper-mb', {
      speed: 800,
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      initialSlide: 1,
      allowTouchMove: true,
    })
  }, [])
  return (
    <div className={classnames(className, styles.liveContainerMB, 'h-[180px]')}>
      <div id="live-swiper-mb" className="live-swiper-mb swiper-container h-full">
        <div className="swiper-wrapper">
          {dataSource?.map((el, index) => {
            return (
              <div className={classnames('swiper-slide')} key={index}>
                <div>
                  <img
                    className="ds2022_card_img w-[100%]"
                    data-src={el?.live_img_bottom?.publicURL || el?.live_img_bottom}
                    alt="神策驱动大会回顾页——现场盛况"
                  />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default Ds2022LiveMb

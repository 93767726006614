/*
 * @Author: liyu
 * @Date: 2022-10-14 10:43:12
 * @LastEditTime: 2022-10-20 16:46:53
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022PlaybackMb/index.tsx
 */
import React, { useEffect, useRef, useState } from 'react'
import { useMount } from 'ahooks'
import Swiper, { Pagination } from 'swiper'
import 'swiper/swiper.min.css'
// import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface Ds2022playbackMbProps {
  className?: string
  dataSource: any
}
Swiper.use([Pagination])
const Ds2022playbackMb: React.FC<Ds2022playbackMbProps> = ({ className, dataSource }) => {
  const mySwiper = useRef<Swiper>()
  const [activeIndex, setActiveIndex] = useState<number>()
  useEffect(() => {
    mySwiper.current = new Swiper('#playback-swiper', {
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      initialSlide: 1,
      // loop: true,
      pagination: {
        el: '.playback-swiper-pagination',
        type: 'bullets',
      },
    })
  }, [])
  return (
    <div className={classnames(className, styles.playbackMbContainer)}>
      <div id="playback-swiper" className="playback-swiper swiper-container">
        <div className="swiper-wrapper">
          {dataSource?.map((item, index) => {
            return (
              <div className={classnames('swiper-slide', styles.swiperSlide)} key={index}>
                <a href={item?.btn_href} target="_blank">
                  <div>
                    <div>
                      {/* <GatsbyImage
                        className="w-[100%]"
                        image={getImage(item?.img_url) as IGatsbyImageData}
                        alt={item?.title}
                      /> */}
                      <img
                        className="w-[100%]  ds2022_card_img"
                        data-src={item?.img_url?.publicURL || item?.img_url}
                        alt={item?.title}
                      />
                    </div>
                    <div
                      className={classnames(
                        ' pt-[8px] pb-[16px] pl-[14px] bg-[#0D1A29] relative',
                        styles.playbackMbCard,
                      )}
                    >
                      <div className="leading-[22px] font-medium text-[16px] ">
                        <span className={styles.textColor}>{item?.title}</span>
                      </div>
                      <div className="text-[#fff] text-[14px] leading-[20px] mt-[8px]">{item?.desc}</div>
                      <div
                        className={classnames(
                          'absolute text-center px-[8px] py-[2px]  top-[8px] right-[10px]',
                          styles.btn,
                        )}
                      >
                        <span
                          className={classnames(styles.textIcon, 'inline-block   w-[16px] h-[10px] mr-[6px] ')}
                        ></span>
                        <span className={classnames(styles.textColor, 'text-[12px]')}>{item?.btn_text}</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            )
          })}
        </div>
        <div className="playback-swiper-pagination"></div>
      </div>
    </div>
  )
}

export default Ds2022playbackMb

/*
 * @Author: liyu
 * @Date: 2022-10-18 11:23:54
 * @LastEditTime: 2022-10-31 11:32:46
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022ContentCard/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface Ds2022ContentCardProps {
  className?: string
  dataSource: any
}

const Ds2022ContentCard: React.FC<Ds2022ContentCardProps> = ({ className, dataSource }) => {
  return (
    <div
      className={classnames(
        'h-[942px] overflow-y-scroll mx-[14px] mt-[20px] pr-[25px]',
        styles.contentContainer,
        className,
      )}
    >
      {dataSource?.bottom?.map((el, key) => {
        return (
          <div
            className={classnames(
              '  text-[#fff]  mb-[10px] bg-[rgba(13,26,41,0.7)] py-[10px] px-[10px] flex',
              styles.contentCard,
            )}
            key={key + '1'}
          >
            <div>
              <img
                width="370px"
                className="rounded-[4px]  ds2022_card_img"
                data-src={el?.img_url?.publicURL || el?.img_url}
                alt={el?.title}
              />
            </div>
            <div className="flex-1 ml-[37px] pr-[10px] relative">
              <h3 className={classnames('inline-block text-[24px] font-medium', styles.textColor)}>{el?.title}</h3>
              <p className="text-[12px] mt-[4px]">{el?.desc}</p>
              <p className="text-[14px] mt-[20px] leading-[24px]">{el?.content}</p>
              {/* <a
                href={el?.btn_link}
                className={classnames(
                  'absolute right-[10px] z-[50] top-[18px] w-[124px] h-[34px] text-center ',
                  styles.contentBtn,
                )}
                target="_blank"
              >
                <span
                  className={classnames(
                    'inline-block leading-[34px] w-[18px] h-[16px]  translate-y-[2px]',
                    styles.contentBtnIcon,
                  )}
                ></span>
                <span className="inline-block leading-[34px] ml-[10px]">{el?.btn_text}</span>
              </a> */}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Ds2022ContentCard

/*
 * @Author: liyu
 * @Date: 2022-10-12 14:59:56
 * @LastEditTime: 2022-10-28 16:20:58
 * @Description: file content
 * @FilePath: /sensors-www/src/pages/topic/ds2022Review/index.tsx
 */
import React, { ReactNode, useRef, useState, useEffect } from 'react'
import Layout from 'layouts/layout'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from 'components/seo'
import Header from 'components/Topic2022/Header'
import Ds2022IntegralBanner from 'components/Topic2022/Ds2022IntegralBanner'
import Ds2022IntegralCard from 'components/Topic2022/Ds2022IntegralCard'
import Ds2022Playback from 'components/Topic2022/Ds2022Playback'
import Ds2022NewsCard from 'components/Topic2022/Ds2022NewsCard'
import Ds2022ContentCard from 'components/Topic2022/Ds2022ContentCard'
import Ds2022ContentCardMb from 'components/Topic2022/Ds2022ContentCardMb'
import Schedule from 'components/Topic2022/Ds2022Schedule'
import Ds2022Live from 'components/Topic2022/Ds2022Live'
import Ds2022LiveMb from 'components/Topic2022/Ds2022LiveMb'
import Ds2022ReviewSwiper from 'components/Topic2022/Ds2022ReviewSwiper'
import Ds2022Footer from 'components/Topic2022/Ds2022Footer'
import { SwiperSlide } from 'swiper/react'
import classnames from 'classnames'
import * as styles from './index.module.less'
import { isPc } from 'utils/utils'

/**
 * 数据维护说明
 * 驱动大会总览页数据维护地址: src/data/ds2022Review/ds2022Review
 */

const Ds2022Review: React.FC = () => {
  const { ds2022ReviewYaml } = useStaticQuery(graphql`
    query {
      ds2022ReviewYaml {
        title
        desc
        keywords
        saTitle
        header_icon
        home_icon
        entrance_one_text
        entrance_one_href
        entrance_two_text
        entrance_two_href
        video_url
        coord_place
        time
        coord_icon
        time_icon
        arrows_icon
        banner_mb
        ds2022Review_icon_mb {
          childImageSharp {
            gatsbyImageData(formats: PNG)
          }
        }
        banner_title
        playback_title
        playback_mask_title
        playback_dataSource {
          img_url {
            publicURL
          }
          title
          desc
          btn_text
          btn_href
        }
        news_title
        news_mask_title
        news_dataSource {
          title
          source
          btn_text
          source_link
          time
        }
        content_title
        content_mask_title
        content_dataSource {
          screen
          top {
            dataTime
            OTO
            title
            schedule_title
            dialog
            dialog_active
            agendas_img
          }
          bottom {
            title
            desc
            content
            img_url {
              publicURL
            }
          }
        }
        live_title
        live_mask_title
        live_dataSource {
          live_img {
            publicURL
          }
          live_img_bottom {
            publicURL
          }
        }
        online_title
        online_mask_title
        review_title
        review_mask_title
        review_dataSource {
          number
          link
          img_url {
            childImageSharp {
              gatsbyImageData(formats: JPG)
            }
          }
          year
          desc
        }
        footer_title
        footer_code
        footer_msg
      }
    }
  `)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const eleNavigation = useRef<any>()
  const observer = useRef<any>(null)
  const handleClickArrow = () => {
    const contents: any = document.querySelector('.ds2022_container[data-n=精彩回放]')
    if (isPc()) {
      document.documentElement.scrollTop = contents?.offsetTop
    }
  }

  const handleOpenPopup = () => {
    window.open('/democenter/overview.html')
  }
  // 回调
  const callback = (entries) => {
    entries.forEach((entry) => {
      // 出现在可视区域
      if (entry.isIntersecting) {
        const image = entry.target
        const data_src = image.getAttribute('data-src')
        image.setAttribute('src', data_src)
        observer.current.unobserve(image)
      }
    })
  }
  useEffect(() => {
    // 图片懒加载
    const images = document.querySelectorAll('.ds2022_card_img')
    observer.current = new IntersectionObserver(callback)
    images.forEach((image) => {
      observer.current.observe(image)
    })
  }, [])
  useEffect(() => {
    let offsetTopArr: any = []
    let nowScrollTop = document.documentElement.scrollTop
    const handleClick = (event: any) => {
      if (event.target?.tagName?.toLowerCase() == 'div') {
        const n = event.target?.getAttribute('data-n')
        const contentPart: HTMLLIElement | null = document.querySelector('.ds2022_container[data-n=' + n + ']')
        if (contentPart) document.documentElement.scrollTop = contentPart?.offsetTop
      }
    }
    const handleScroll = () => {
      nowScrollTop = document.documentElement.scrollTop
      if (nowScrollTop >= offsetTopArr[0]) {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '1'
        }
      } else {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '0'
        }
      }
      offsetTopArr.map((topItem, index) => {
        if (nowScrollTop >= topItem && nowScrollTop < offsetTopArr[index + 1]) {
          setCurrentIndex(index)
          return
        }
      })
    }
    if (isPc() && eleNavigation.current) {
      eleNavigation.current?.addEventListener('click', handleClick)

      // 监听滚动
      const contents: any = document.querySelectorAll('.ds2022_container')

      for (let i = 0; i < contents.length; i++) {
        offsetTopArr.push(contents[i]?.offsetTop - 200)
      }
      // 为了方便比较，追加无穷大
      offsetTopArr.push(Infinity)

      if (nowScrollTop >= offsetTopArr[0]) {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '1'
        }
      } else {
        if (eleNavigation.current?.style) {
          eleNavigation.current.style.opacity = '0'
        }
      }
      offsetTopArr.map((topItem, index) => {
        if (nowScrollTop >= topItem && nowScrollTop < offsetTopArr[index + 1]) {
          setCurrentIndex(index)
          return
        }
      })
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      if (isPc() && eleNavigation.current) {
        eleNavigation.current.removeEventListener('click', handleClick)
        window.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])
  return (
    <Layout>
      <SEO
        title={ds2022ReviewYaml?.title}
        description={ds2022ReviewYaml?.desc}
        keywords={ds2022ReviewYaml?.keywords}
        saTitle={ds2022ReviewYaml?.saTitle}
      />
      {/* 头部 */}
      <Header
        videoFlag={true}
        imgUrl={ds2022ReviewYaml?.header_icon?.publicURL || ds2022ReviewYaml?.header_icon}
        entrance_one_text={ds2022ReviewYaml?.entrance_one_text}
        entrance_one_href={ds2022ReviewYaml?.entrance_one_href}
        entrance_two_text={ds2022ReviewYaml?.entrance_two_text}
        entrance_two_href={ds2022ReviewYaml?.entrance_two_href}
        home_icon={ds2022ReviewYaml?.home_icon?.publicURL || ds2022ReviewYaml?.home_icon}
      />
      <main>
        <Ds2022IntegralBanner
          className="relative  lg:min-h-[100vh]"
          videoFlag={true}
          banner_title={ds2022ReviewYaml?.banner_title}
          video_url={ds2022ReviewYaml?.video_url}
          banner_mb={ds2022ReviewYaml?.banner_mb?.publicURL || ds2022ReviewYaml?.banner_mb}
          time_icon={ds2022ReviewYaml?.time_icon?.publicURL || ds2022ReviewYaml?.time_icon}
          coord_icon={ds2022ReviewYaml?.coord_icon?.publicURL || ds2022ReviewYaml?.coord_icon}
          coord_place={ds2022ReviewYaml?.coord_place}
          time={ds2022ReviewYaml?.time}
          arrows_icon={ds2022ReviewYaml?.arrows_icon?.publicURL || ds2022ReviewYaml?.arrows_icon}
          ds2022_icon_mb={ds2022ReviewYaml?.ds2022Review_icon_mb}
          entrance_one_text={ds2022ReviewYaml?.entrance_one_text}
          entrance_one_href={ds2022ReviewYaml?.entrance_one_href}
          entrance_two_text={ds2022ReviewYaml?.entrance_two_text}
          entrance_two_href={ds2022ReviewYaml?.entrance_two_href}
          onClickArrow={handleClickArrow}
        />
        <article className={classnames(styles.ds2022ReviewContainer)}>
          {/* 精彩回放 */}
          <Ds2022IntegralCard
            title={ds2022ReviewYaml?.playback_title}
            maskTitle={ds2022ReviewYaml?.playback_mask_title}
            paddingX={false}
            dataN="精彩回放"
          >
            <Ds2022Playback dataSource={ds2022ReviewYaml?.playback_dataSource} />
          </Ds2022IntegralCard>
          {/* 新闻报道 */}
          <Ds2022IntegralCard
            dataN="新闻报道"
            title={ds2022ReviewYaml?.news_title}
            maskTitle={ds2022ReviewYaml?.news_mask_title}
          >
            {ds2022ReviewYaml?.news_dataSource?.map((item, index) => {
              return <Ds2022NewsCard dataSource={item} className={styles.newsCard} key={index} />
            })}
          </Ds2022IntegralCard>
          {/* 内容精华 */}
          <Ds2022IntegralCard
            dataN="内容精华"
            title={ds2022ReviewYaml?.content_title}
            maskTitle={ds2022ReviewYaml?.content_mask_title}
            className={styles.contentContainer}
          >
            <Schedule
              dataSource={ds2022ReviewYaml?.content_dataSource}
              slot={ds2022ReviewYaml?.content_dataSource?.map((item, index) => {
                return (
                  <SwiperSlide key={index + '内容精华'}>
                    <>
                      <Ds2022ContentCard dataSource={item} />
                      <Ds2022ContentCardMb dataSource={item} />
                    </>
                  </SwiperSlide>
                )
              })}
            />
          </Ds2022IntegralCard>
          {/* 现场盛况 */}
          <Ds2022IntegralCard
            dataN="现场盛况"
            title={ds2022ReviewYaml?.live_title}
            maskTitle={ds2022ReviewYaml?.live_mask_title}
            paddingX={false}
            DsSlot={
              <>
                <Ds2022Live dataSource={ds2022ReviewYaml?.live_dataSource} />
                <Ds2022LiveMb dataSource={ds2022ReviewYaml?.live_dataSource} />
              </>
            }
          ></Ds2022IntegralCard>
          {/* 线上演示中心 */}
          {/* 20230630 版权问题，删除该板块 */}
          {/* <Ds2022IntegralCard
            dataN="线上演示中心"
            title={ds2022ReviewYaml?.online_title}
            maskTitle={ds2022ReviewYaml?.online_mask_title}
            className={styles.onlineContainer}
            DsSlot={
              <div className="w-[1200px] mx-auto h-[675px] relative">
                <img
                  className="absolute top-0 left-0 right-0 bottom-0 ds2022_card_img"
                  data-src="https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/icon_schedule_bg_new.png"
                  alt={ds2022ReviewYaml?.online_title}
                />
                <div
                  className="absolute w-[785px] top-[348px] left-[80px]  text-center  h-[234px] pt-[42px]"
                  style={{
                    backgroundImage: `url(https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/icon_schedule_new.png)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100% 100%',
                  }}
                >
                  <div className="ml-auto mr-[52px] w-[580px]">
                    <h3 className="text-[24px] font-medium text-[#000]">大家好，我是神策数据驱动大会小助理“小数点”</h3>
                    <div className="mt-[17px] text-[14px] text-[#5a5757] leading-[24px]">
                      <p className="text-left">
                        我们在线上展区为大家准备了 15+ 专题的体验，有场景讲解视频、行业干货资料、指标拆解工具、Demo
                        体验等
                      </p>
                    </div>
                  </div>
                  <div
                    className="w-[107px] mt-[30px] cursor-pointer h-[30px] mx-auto text-center text-[14px] leading-[30px] text-[#000] font-medium"
                    style={{
                      backgroundImage: `url(https://ow-file.sensorsdata.cn/www/topic/ds2022/overview/explore_button_bg.svg)`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: '100% 100%',
                    }}
                    onClick={handleOpenPopup}
                  >
                    立即探索
                  </div>
                </div>
              </div>
            }
          ></Ds2022IntegralCard> */}
          {/* 往届回顾 */}
          <Ds2022IntegralCard
            dataN="往届回顾"
            title={ds2022ReviewYaml?.review_title}
            maskTitle={ds2022ReviewYaml?.review_mask_title}
            DsSlot={
              <div className="lg:w-[1200px] mx-auto ">
                <Ds2022ReviewSwiper dataSource={ds2022ReviewYaml?.review_dataSource} />
              </div>
            }
          />
          <Ds2022Footer
            title={ds2022ReviewYaml?.footer_title}
            message={ds2022ReviewYaml?.footer_msg}
            code={ds2022ReviewYaml?.footer_code?.publicURL || ds2022ReviewYaml?.footer_code}
          />
        </article>
      </main>
      {/* 电梯导航栏 */}
      <ul
        ref={eleNavigation}
        id="elevator_navigation"
        className={classnames(
          'hidden lg:block fixed  text-[14px] lg:z-[999]   top-[50%] left-[0] translate-y-[-100px]',
          styles.elevatorNavigation,
        )}
      >
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 0 })}>
          <div data-n="精彩回放" className={classnames(styles.navigation_item_content)}>
            精彩回放
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 1 })}>
          <div data-n="新闻报道" className={classnames(styles.navigation_item_content)}>
            新闻报道
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 2 })}>
          <div data-n="内容精华" className={classnames(styles.navigation_item_content)}>
            内容精华
          </div>
        </li>
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 3 })}>
          <div data-n="现场盛况" className={classnames(styles.navigation_item_content)}>
            现场盛况
          </div>
        </li>
        {/* <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 4 })}>
          <div data-n="线上演示中心" className={classnames(styles.navigation_item_content)}>
            线上展区
          </div>
        </li> */}
        <li className={classnames(styles.navigation_item, { active_navigation: currentIndex === 5 })}>
          <div data-n="往届回顾" className={classnames(styles.navigation_item_content)}>
            往届回顾
          </div>
        </li>
      </ul>
    </Layout>
  )
}

export default Ds2022Review

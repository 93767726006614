/*
 * @Author: liyu
 * @Date: 2022-10-28 10:21:40
 * @LastEditTime: 2022-10-31 20:52:31
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022Live/index.tsx
 */
import React, { useEffect, useRef } from 'react'
import classnames from 'classnames'
import Swiper, { Navigation, Thumbs } from 'swiper'
import 'swiper/swiper.min.css'
import * as styles from './index.module.less'

interface Ds2022LiveProps {
  className?: string
  dataSource: { live_img: any; live_img_bottom: any }[]
}
Swiper.use([Navigation, Thumbs])
const Ds2022Live: React.FC<Ds2022LiveProps> = ({ className, dataSource }) => {
  const liveSwiper = useRef<Swiper>()
  const topSwiper = useRef<Swiper>()
  useEffect(() => {
    liveSwiper.current = new Swiper('#live-swiper', {
      spaceBetween: 0,
      slidesPerView: 4,
      //   freeMode: true,
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    })
    topSwiper.current = new Swiper('#top-swiper', {
      spaceBetween: 0,
      navigation: {
        nextEl: '.top-swiper-button-next',
        prevEl: '.top-swiper-button-prev',
      },
      thumbs: {
        swiper: liveSwiper.current,
      },
    })
  }, [])
  return (
    <div className={classnames(className, 'w-full max-w-[1400px] mx-auto relative', styles.liveContainer)}>
      <div className="w-[1200px] mx-auto relative">
        <div id="top-swiper" className="top-swiper overflow-hidden">
          <div className="swiper-wrapper">
            {dataSource?.map((item, index) => {
              return (
                <div className="swiper-slide " key={index}>
                  <div className="w-[1200px] h-[674px] ">
                    {/* <GatsbyImage
                        className="w-[100%] h-[100%]"
                        image={getImage(item?.live_img) as IGatsbyImageData}
                        alt="神策驱动大会回顾页——现场盛况"
                      /> */}
                    <img
                      className="!rounded-[4px] ds2022_card_img w-[100%] h-[100%] "
                      data-src={item?.live_img?.publicURL || item?.live_img}
                      alt="神策驱动大会回顾页——现场盛况"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div id="live-swiper" className="live-swiper overflow-hidden absolute left-0 right-0 bottom-[26px]">
          <div className="swiper-wrapper">
            {dataSource?.map((item, index) => {
              return (
                <div className="swiper-slide" key={index + 1}>
                  <div className="w-[250px] mx-auto">
                    {/* <GatsbyImage
                      image={getImage(item?.live_img_bottom) as IGatsbyImageData}
                      alt="神策驱动大会回顾页——现场盛况"
                    /> */}
                    <img
                      className="rounded-[4px] ds2022_card_img"
                      data-src={item?.live_img_bottom?.publicURL || item?.live_img_bottom}
                      alt="神策驱动大会回顾页——现场盛况"
                    />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="top-swiper-button-next "></div>
      <div className="top-swiper-button-prev "></div>
    </div>
  )
}
export default Ds2022Live

/*
 * @Author: liyu
 * @Date: 2022-10-18 11:33:56
 * @LastEditTime: 2022-10-31 10:49:14
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022ContentCardMb/index.tsx
 */
import React, { useEffect } from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface Ds2022ContentCardMb {
  className?: string
  dataSource: any
}

const Ds2022ContentCardMb: React.FC<Ds2022ContentCardMb> = ({ className, dataSource }) => {
  return (
    <div className={classnames(className, styles.cardMb, 'text-[#fff]')}>
      <div className={classnames(styles.cardMbHeader, 'py-[4px] pl-[6px]')}>
        <span className={classnames('leading-[24px] inline-block text-[14px] font-medium', styles.textColor)}>
          {dataSource?.top?.title}
        </span>
        <span className="text-[12px] ml-[5px]">{dataSource?.top?.schedule_title}</span>
      </div>
      <div>
        {dataSource?.bottom?.map((item, index) => {
          return (
            <div
              className={classnames('block relative mt-[8px] bg-[rgba(13,26,41,0.7)] ', styles.contentCardMb)}
              key={index}
            >
              <div className="relative">
                <img
                  className={classnames('ds2022_card_img w-[100%] h-[190px] rounded-[4px]', styles.images)}
                  width="100%"
                  data-src={item?.img_url?.publicURL || item?.img_url}
                  alt=""
                />
                <p
                  className={classnames('absolute text-[14px] font-medium left-[10px] bottom-[40px]', styles.textColor)}
                >
                  {item?.title}
                </p>
                <p className="absolute text-[12px] left-[10px] top-[154px]">{item?.desc}</p>
              </div>
              <p className="px-[8px] pt-[8px] pb-[36px] text-[12px]">{item?.content}</p>

              {/* <p className={classnames('absolute px-[10px]   bottom-[10px] right-[10px] py-[2px]', styles.contentBtn)}>
                <span className={classnames(styles.contentBtnIcon, 'inline-block w-[12px] h-[10px] mr-[6px]')}></span>
                <span className={classnames('text-[12px] inline-block', styles.textColor)}>{item?.btn_text}</span>
              </p> */}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Ds2022ContentCardMb

/*
 * @Author: liyu
 * @Date: 2022-10-17 14:35:20
 * @LastEditTime: 2022-10-20 15:08:04
 * @Description: file content
 * @FilePath: /sensors-www/src/components/Topic2022/Ds2022NewsCard/index.tsx
 */
import React from 'react'
import classnames from 'classnames'
import * as styles from './index.module.less'

interface Ds2022NewsCardProps {
  className?: string
  dataSource: any
}

const Ds2022NewsCard: React.FC<Ds2022NewsCardProps> = ({ className, dataSource }) => {
  return (
    <div className={classnames(className)}>
      {/* pc */}
      <a
        className={classnames(
          ' mx-auto w-[1080px] h-[86px] bg-[rgba(13,26,41,0.7) ] py-[16px] px-[20px] flex',
          styles.container,
        )}
        href={dataSource?.source_link}
        target="_blank"
      >
        <div className={classnames('w-[54px] h-full text-center', styles.newsTime)}>
          <span className={classnames('inline-block pt-[28px] font-medium text-[16px]', styles.textColor)}>
            {dataSource?.time}
          </span>
        </div>
        <div className="ml-[64px]">
          <div
            className={classnames(
              'w-[675px] inline-block text-[16px] leading-[22px] font-medium truncate',
              styles.textColor,
            )}
          >
            {dataSource?.title}
            {/* 神策 2021 数据驱动大会，桑文锋等发出营销未来之强音销未来之强音科特勒、销未来之强音销未来之强音销未来之强音… */}
          </div>
          <div className="text-[12px] text-[#fff] opacity-[0.5] mt-[8px]">{dataSource?.source}</div>
        </div>
        <div className="ml-[154px]">
          <div className="inline-block leading-[52px]">
            <span className={classnames(styles.textColor, 'text-[12px] font-medium leading-[24px]')}>
              {dataSource?.btn_text}
            </span>
            <span
              className={classnames('inline-block ml-[6px] w-[16px] h-[13px] translate-y-[2px]', styles.arrows)}
            ></span>
          </div>
        </div>
      </a>
      {/* h5 */}
      <a href={dataSource?.source_link} className={classnames(styles.containerMb, ' text-[#fff]')}>
        <div className={classnames(' text-[16px] font-medium', styles.titleMb)}>{dataSource?.title}</div>
        <div className="mt-[10px] flex justify-between">
          <div className="opacity-[0.5]">
            2022-10-{dataSource?.time} {dataSource?.source}
          </div>
          <div>
            <span className={classnames(styles.textColorMb)}>{dataSource?.btn_text}</span>
            <span
              className={classnames(styles.arrowsActive, 'inline-block w-[16px] h-[11px] ml-[6px] translate-y-[1px]')}
            ></span>
          </div>
        </div>
      </a>
    </div>
  )
}

export default Ds2022NewsCard
